@use './fonts.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
}

button {
  cursor: pointer;
  background: none;
  border: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}
