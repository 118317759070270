//NotoSansKR
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/NotoSansKR/NotoSansKR-Thin.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Thin.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/NotoSansKR/NotoSansKR-Light.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NotoSansKR/NotoSansKR-Regular.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/NotoSansKR/NotoSansKR-Medium.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/NotoSansKR/NotoSansKR-Bold.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/NotoSansKR/NotoSansKR-Black.woff2') format('woff2'),
    url('../fonts/NotoSansKR/NotoSansKR-Black.woff') format('woff');
  font-display: swap;
}

//Inter
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.woff2?v=3.19') format('woff2'),
    url('../fonts/Inter/Inter-Regular.woff?v=3.19') format('woff');
  font-display: swap;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.woff2?v=3.19') format('woff2'),
    url('../fonts/Inter/Inter-Bold.woff?v=3.19') format('woff');
  font-display: swap;
  unicode-range: U+0030-0039, U+0041-005A, U+0061-007A;
}
